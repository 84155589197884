import './App.css';
import GoogleSheet2 from './HomePage/GoogleSheet2';
import HomePageText from './HomePage/HomePage';

function App() {

  return (
    <div className='App'>
      <GoogleSheet2></GoogleSheet2>
    </div>
  );
}

export default App;
